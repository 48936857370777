<template>
  <div class="content">
    <div class="hello">
      <h1>Qwerty Dev</h1>
      <p>This subdomain is used for side projects and other stuff!</p>
      <sub>Made by <a href="https://qwerty.daxel.pl/">Qwerty</a> with Vue.js</sub>
    </div>
  </div>
  </template>

  <style scoped>
    sub {
      color: lightgray;
    }
    a {
      color: #42b983;
      text-decoration: none;
    }
    a:hover {
      color: #13b46c;
      text-decoration: underline;
    }
    .hello {
      text-align: center;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100vw;
      font: 'Montserrat', sans-serif;
    }
  </style>
  
  <script>
  import anime from 'animejs';

  setTimeout(() => {
    anime({
        targets: '.hello',
        duration: 5000,
        scale: 0.1,
        keyframes: [
            {scale: 1.2, rotate: '1turn', opacity: 1},
        ],
        easing: 'spring(1, 80, 10, 0)',
    })
  })
  export default {
    name: 'HomeComp',
  }
  </script>
  